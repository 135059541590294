import { isEmpty } from 'lodash';

import {
  Company,
  DriftCompanyAttributes,
  AdminCompanyDto,
  ClaimRequestStatus,
  CompanyStatusEnum,
  SealTypeEnum,
} from 'store/models/Company';
import { User } from 'store/models/User';
import { getCompanyByDuns, getCompanyById } from 'store/selectors';
import { getEmails, getPhones } from 'utils/companyFormatter';

export const getCompanyLogo = (company: Company | AdminCompanyDto): string => {
  return company.logo;
};

export const getCompanyName = (company: Company | AdminCompanyDto): string => {
  if ('legalName' in company) {
    return company.legalName;
  }
  return company.primaryName;
};

export function getCurrentCompany(user: User, currentCompanyIdFromCookie?: string): AdminCompanyDto | null {
  const nonDeclinedCompanies =
    user?.companies?.filter((company) => company.claimStatus !== ClaimRequestStatus.DECLINED) || [];

  const companyMatch =
    currentCompanyIdFromCookie &&
    nonDeclinedCompanies.find((company) => company.aclCompanyId === currentCompanyIdFromCookie);

  return companyMatch || nonDeclinedCompanies[0] || null;
}

export function getOtherCompanies(user: User): AdminCompanyDto[] {
  const currentCompanyId = user.currentCompany?.aclCompanyId;

  const isNotDeclined = (company: AdminCompanyDto) => company.claimStatus !== ClaimRequestStatus.DECLINED;

  const isNotCurrentCompany = (company: AdminCompanyDto) => company.aclCompanyId !== currentCompanyId;

  const filterCompanies = (company: AdminCompanyDto) => {
    return currentCompanyId ? isNotCurrentCompany(company) && isNotDeclined(company) : isNotDeclined(company);
  };

  const otherCompanies = user.companies.filter(filterCompanies);

  return otherCompanies;
}

export const getDriftCompanyAttributes = (company: Company): DriftCompanyAttributes => {
  const shortLocationArr = company.shortLocation?.split(',') || [];
  const leadCountry = shortLocationArr[shortLocationArr.length - 1]?.trim() || '';
  const leadPhoneNumber = getPhones(company)?.join(',') || '';
  const leadEmail = getEmails(company)?.join(',') || '';
  const leadSicCode = company.industries?.map((industry) => industry.id).join(', ');

  return {
    leadTaxId: company.taxId,
    leadWebsite: company.website,
    leadSocialMedia: `${company.instagram} ${company.facebook} ${company.tiktok} ${company.linkedin} ${company.twitter} ${company.youtube}`,
    leadPhoneNumber,
    leadEmail,
    leadCountry,
    leadSicCode,
  };
};

export function isApprovedAndHasDuns(user: User): boolean {
  if (user.currentCompany) {
    const approvedCompany = user.currentCompany.status === CompanyStatusEnum.ACTIVE;
    if (approvedCompany) {
      // check if approved company has duns
      return Boolean(user.currentCompany.duns);
    }
    return false;
  }
  return false;
}

export const getCertificationTitle = (sealType: SealTypeEnum) =>
  ({
    [SealTypeEnum.ESG_REGISTERED]: 'ESG Registered™',
    [SealTypeEnum.DUNS_REGISTERED]: 'D-U-N-S® Registered™',
    [SealTypeEnum.DUNS_CREDIBILITY]: 'Credibility Certificate',
    [SealTypeEnum.DUNS_CREDIBILITY_PREMIUM]: 'Credibility Certificate Premium',
    [SealTypeEnum.DUNS_100]: 'D-U-N-S® 100',
  }[sealType]);

export const isClaimedByYou = (companyId?: string, user?: User): boolean => {
  const companyConnectedToUser = getCompanyById(companyId, user) || getCompanyByDuns(companyId, user);
  return !isEmpty(companyConnectedToUser) ? true : false;
};
