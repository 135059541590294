import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pollingCallsSatAuthorization: 0,
  pollingCallsBuroAuthorization: 0,
  isOpenChatAI: false,
  questionChatAI: '',
};

const pulso = createSlice({
  name: 'pulso',
  initialState,
  reducers: {
    incrementPollingCallsSatAuthorization: (state) => {
      state.pollingCallsSatAuthorization += 1;
    },
    clearPollingCountSatAuthorization: (state) => {
      state.pollingCallsSatAuthorization = 0;
    },
    incrementPollingCallsBuroAuthorization: (state) => {
      state.pollingCallsBuroAuthorization += 1;
    },
    clearPollingCountBuroAuthorization: (state) => {
      state.pollingCallsBuroAuthorization = 0;
    },
    toggleChatAI: (state) => {
      state.isOpenChatAI = !state.isOpenChatAI;
    },
    showChatAI: (state, action) => {
      state.isOpenChatAI = action.payload.show;
      state.questionChatAI = action.payload.questionChatAI;
    },
  },
});

export const { reducer, name: reducerPath } = pulso;
export const {
  incrementPollingCallsSatAuthorization,
  clearPollingCountSatAuthorization,
  incrementPollingCallsBuroAuthorization,
  clearPollingCountBuroAuthorization,
  toggleChatAI,
  showChatAI,
} = pulso.actions;
