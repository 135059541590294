export enum CredentialsValidationResponseStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export interface CreateClaimCiecResponseDto {
  id: string;
  uuid: string;
}

export interface CreateClaimResponseDto {
  id: string;
  uuid: string;
}

export enum StatisticType {
  EMPLOYEES_BY_AGE = 'employees_by_age',
  EMPLOYEES_BY_COUNTRY = 'employees_by_country',
  EMPLOYEES_BY_SENIORITY = 'employees_by_seniority',
  COMMUNITY_CONTRIBUTIONS = 'community_contributions',
  MALE_FEMALE_RATIO_MANAGERS = 'male_female_ratio_managers',
  MALE_FEMALE_RATIO = 'male_female_ratio',
  NUMBER_OF_EMPLOYEES = 'number_of_employees',
  EXPORT_RATIO = 'export_ratio',
  ANNUAL_REVENUE = 'annual_revenue',
}

export enum CloudinaryFolders {
  GALLERY = 'gallery',
  PERSON = 'person',
  LOGO = 'logo',
  BACKGROUND = 'background',
}

export enum COMPANY_REQUIRED_DOCUMENTS {
  articles_of_incorporation = 'documents.articles_of_incorporation',
  bylaws = 'documents.bylaws',
  partnership_agreement = 'documents.partnership_agreement',
  board_of_directors_agreement = 'documents.board_of_directors_agreement',
  board_of_directors_election_act = 'documents.board_of_directors_election_act',
  federal_taxpayer_registration = 'documents.federal_taxpayer_registration',
  operating_license = 'documents.operating_license',
  registration_form = 'documents.registration_form',
  act_of_foundation = 'documents.act_of_foundation',
  tax_registration = 'documents.tax_registration',
  constitution_minutes = 'documents.constitution_minutes',
  company_registration = 'documents.company_registration',
  public_deed_of_incorporation = 'documents.public_deed_of_incorporation',
  commercial_registry = 'documents.commercial_registry',
  mercantile_registry = 'documents.mercantile_registry',
  copy_of_tax_registration_or_articles_of_incorporation = 'documents.copy_of_tax_registration_or_articles_of_incorporation',
}

export enum OnboardingStatus {
  AutomaticVerificationPending = 'AutomaticVerificationPending',
  AutomaticVerificationCompleted = 'AutomaticVerificationCompleted',
  ManualVerificationPending = 'ManualVerificationPending',
  ManualVerificationCompleted = 'ManualVerificationCompleted',
  CompleteDataProfilePending = 'CompleteDataProfilePending',
  CompleteDataProfileCompleted = 'CompleteDataProfileCompleted',
  OnboardingComplete = 'OnboardingComplete',
  NotOnboarding = 'NotOnboarding',
  Close = 'Close',
}

export enum PulsoStatus {
  SatConnectionPending = 'SatConnectionPending',
  SatConnectionCompleted = 'SatConnectionCompleted',
  IdentityVerificationPending = 'IdentityVerificationPending',
  IdentityVerificationCompleted = 'IdentityVerificationCompleted',
  BuroAuthorizationPending = 'BuroAuthorizationPending',
  BuroAuthorizationCompleted = 'BuroAuthorizationCompleted',
}
