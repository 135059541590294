import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/he';
import React, { createContext, useEffect, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CIALNegativeLogo from 'public/images/CIAL_logo_negative.png';
import CIALOfficialLogo from 'public/images/CIAL_logo_official.png';
import DBIsraelLogo from 'public/images/D_B_Israel_logo.png';
import DBIsraelLogoWhite from 'public/images/D_B_Israel_logo_white.png';
import DunsBadgeCredibility from 'public/images/duns_badge_credibility.png';
import DunsBadgeCredibilityHebrew from 'public/images/duns_badge_credibility_hebrew.png';
import DunsRegistered from 'public/images/DUNS_registered_home.png';
import { useGetLocationByCodeQuery } from 'store/api';
import { removeParamsFromPath, getLanguageAlternates } from 'utils/countryRoute';
import { countryMeta, languageMeta } from 'utils/localeConstants';
import { getTranslated, getSlug, getSearchPath } from 'utils/localization';
import { getFullPath } from 'utils/routes';

export const InternationalizationContext = createContext();

const propTypes = {
  host: PropTypes.string,
  language: PropTypes.string,
  country: PropTypes.string, //iso2
  children: PropTypes.node.isRequired,
};

const InternationalizationContextProvider = ({ country, language, host, geo, selectedLanguage, children }) => {
  const { t } = useTranslation('calendar');

  const [direction, setDirection] = useState(languageMeta[language]?.direction || 'ltr');

  const domainCountryMeta = country ? countryMeta[country.toLowerCase()] : null;

  const { data: location = {}, isFetching: isFetchingLocation } = useGetLocationByCodeQuery(
    { locationCode: domainCountryMeta?.iso3 },
    { skip: !domainCountryMeta?.iso3 },
  );

  useEffect(() => {
    document.documentElement.lang = `${language}${country ? `-${country.toUpperCase()}` : ''}`;
  }, [language, country]);

  useEffect(() => {
    document.documentElement.dir = languageMeta[language]?.direction || 'ltr';
    setDirection(languageMeta[language]?.direction || 'ltr');
  }, [language]);

  useEffect(() => {
    // TODO this is awful, find a better way
    document.documentElement.dir = languageMeta[language]?.direction || 'ltr';
    setDirection(languageMeta[language]?.direction || 'ltr');
  }, [language]);

  const domainLocationCountry = {
    en: 'Latin America',
    es: 'América Latina',
    pt: 'América Latina',
  };

  const domainLocationDisplayName = country
    ? getTranslated(countryMeta[country]?.description, null, language)
    : getTranslated(domainLocationCountry, null, language);

  const partner =
    country && country.toUpperCase() === 'IL'
      ? {
          name: {
            en: 'Dun & Bradstreet Israel',
            he: 'דן אנד ברדסטריט ישראל',
          },
          region: {
            en: 'Israel',
            he: 'ישראל',
          },
          phone: {
            en: '03-7330480',
          },
          mail: {
            en: 'info@dbisrael.co.il',
          },
          openHours: {
            en: 'Sun-Thurs 09:00-18:00',
            he: `א'-ה' 09:00-18:00`,
          },
          logoWhite: {
            en: DBIsraelLogoWhite,
          },
          logo: {
            en: DBIsraelLogo,
          },
          baseLink: 'dbisrael.co.il',
          link: {
            en: 'https://www.dbisrael.co.il/en/?utm_source=dunsguide',
            he: 'https://www.dbisrael.co.il/?utm_source=dunsguide',
          },
          seal: {
            en: 'Dun’s Credibility',
            he: 'אֲמִינוּת',
          },
          badge: {
            en: DunsBadgeCredibility,
            he: DunsBadgeCredibilityHebrew,
          },
          isIsrael: true,
          social: {
            facebook: 'https://www.facebook.com/dnbisrael',
            instagram: '',
            linkedin: 'http://www.linkedin.com/company/dun-&-bradstreet-israel-ltd.',
            twitter: 'https://twitter.com/dbisrael',
            youtube: 'https://www.youtube.com/dbisraelvideo',
          },
          introduction: 'introduction_israel',
          offices: [
            {
              name: 'Tel Aviv',
              phone: '+972-3-7330333',
              email: 'info@dbisrael.co.il',
              address: '53 Derech Hashalom St., Givatayim 5345433P.O.B. 25375, Tel-Aviv 6125301',
              hours: `${t('weekend_days.sunday.abbreviation')}-${t('week_days.thursday.abbreviation')} - 00-23h`,
            },
          ],
        }
      : {
          name: {
            en: 'CIAL Dun & Bradstreet',
          },
          region: {
            en: 'Latin America',
            es: 'América Latina',
            pt: 'América Latina',
          },
          phone: {
            en: '+55 11 4933 7500',
          },
          mail: {
            en: 'clientes@cialdnb.com',
          },
          logoWhite: {
            en: CIALNegativeLogo,
          },
          logo: {
            en: CIALOfficialLogo,
          },
          baseLink: 'cialdnb.com',
          link: {
            en: 'https://www.cialdnb.com/?utm_source=dunsguide',
            es: 'https://www.cialdnb.com/es/?utm_source=dunsguide',
            pt: 'https://www.cialdnb.com/pt-br/?utm_source=dunsguide',
          },
          seal: {
            en: 'D-U-N-S® Registered™',
          },
          badge: {
            en: DunsRegistered,
          },
          isIsrael: false,
          social: {
            facebook: 'https://www.facebook.com/cialdnb/',
            instagram: '',
            linkedin: 'https://www.linkedin.com/company/cial-dun-&-bradstreet/',
            twitter: '',
            youtube: '',
          },
          introduction: 'introduction_cial',
          offices: [
            {
              name: 'Mexico',
              phone: '+52 55 4166-3000',
              address: 'Durango 263, Pisos 5 Col. Roma. 06700 Mexico, D.F',
            },
            {
              name: 'Brazil',
              phone: '+55 11 4933 7500',
              address: 'Av. Bernardino de Campos 98, 2 Andar - Conjunto 22. 04004-040 Sao Paulo, SP, Brazil',
            },
            {
              name: 'Argentina',
              phone: '+54 11 5984 2700',
              address:
                'Av. Corrientes 456 - Piso 8, Oficina 81, 82, 85 y 86. C1043AAR,Ciudad Autónoma de Buenos Aires, Argentina',
            },
            {
              name: 'Peru',
              phone: '+511 7063200',
              address: 'Av. La Encalada 1420 Of. 802. Centro Empresarial Polo Hunt II Lima 33, Peru',
            },
            {
              name: 'Central America & Caribbean',
              phone: '+1 954-507-1116',
              address: '560 Sawgrass Corporate Parkway. Suite 120. Sunrise, FL 33323, USA',
            },
          ],
        };

  const getCanonical = (path, options) => {
    const domain = `${process.env.NEXT_PUBLIC_APP_PROTOCOL}://${process.env.NEXT_PUBLIC_DOMAIN_DEFAULT}`;
    let pathWithCleanedParams = removeParamsFromPath(path, options?.includeParams);

    pathWithCleanedParams = pathWithCleanedParams === '/' ? '' : pathWithCleanedParams;

    const pathWithCorrectLanguage = getFullPath(language, 'en', pathWithCleanedParams);

    return `${domain}${pathWithCorrectLanguage}`;
  };

  const getMetaTags = (path, options) => ({
    canonical: getCanonical(path, options),
    languageAlternates: getLanguageAlternates(path, options),
  });

  return (
    <div>
      <InternationalizationContext.Provider
        value={{
          language,
          supportedLanguages: (country && countryMeta[country.toLowerCase()]?.supportedLanguages) || ['es', 'pt', 'en'],
          country,
          partner,
          direction,
          domainCountryMeta,
          domainLocation: location,
          domainLocationDisplayName,
          isFetchingDomainLocation: isFetchingLocation,
          getTranslated: (object, key, defaultValue, options) =>
            getTranslated(object, key, language, defaultValue, options),
          getSlug: (object) => getSlug(object, language),
          getSearchPath: (query) => getSearchPath(query),
          getMetaTags,
          getLocalizedMoment: (date = undefined) => moment(date).locale(language),
          getCanonical: (path, options) => getCanonical(path, options),
          host,
          geo,
          selectedLanguage,
          countriesNames: Object.keys(countryMeta)
            .map((iso2) => getTranslated(countryMeta[iso2], 'description'))
            .sort(),
        }}
      >
        {children}
      </InternationalizationContext.Provider>
    </div>
  );
};

InternationalizationContextProvider.propTypes = propTypes;

export default InternationalizationContextProvider;
