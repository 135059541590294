import { AdminCompanyDto, ClaimRequestStatus } from './models/Company';
import { User } from './models/User';

/*

// Getters used to extract specific parts of the user state, such as companies and claims.

example of usage

const user = useUser()
const company = getCompanyById(123, user);

*/

export const getCompanies = (user?: User): AdminCompanyDto[] => {
  return user ? user.companies : [];
};

export const getCompanyById = (id?: string, user?: User): AdminCompanyDto | undefined => {
  return getCompanies(user).find((company) => company.aclCompanyId === id);
};

export const getCompanyByDuns = (
  duns?: string,
  user?: User,
  requiresApproval: boolean = false,
): AdminCompanyDto | undefined => {
  return getCompanies(user).find(
    (company) => (!requiresApproval || company.claimStatus === ClaimRequestStatus.APPROVED) && company.duns === duns,
  );
};

/*

// Selectors used in conjunction with the "useUser" hook to retrieve specific information from the user object.

example of usage

const company = useUser(selectCompanyById(123))

*/

export const selectCompanyById = (id: string) => (user?: User) => {
  return getCompanyById(id, user);
};

export const selectUserAndCompanyById = (id: string) => (user?: User) => {
  const company = getCompanyById(id, user);
  return {
    user,
    company,
  };
};

export const selectUserAndCompanyByDuns = (duns?: string) => (user?: User) => {
  const company = getCompanyByDuns(duns, user);
  return {
    user,
    company,
  };
};

export const selectUserAndCompanyByDunsOrId = (dunsOrId?: string) => (user?: User) => {
  const company = getCompanyByDuns(dunsOrId, user) || getCompanyById(dunsOrId, user);
  return {
    user,
    company,
  };
};
